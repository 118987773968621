import React from 'react';

const Bubble = () => {
  return (
    <div id='particles-js'>
      <div style={{ position: 'absolute' }}></div>
    </div>
  );
};

export default Bubble;
